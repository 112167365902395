<template>
  <div
    class="search-container"
    :class="`${isUserLogged ? 'logged' : 'not-logged'}`"
  >
    <div
      class="search-input"
      :class="showResults && querySearch ? 'results' : ''"
    >
      <ElInput
        class="landing-search"
        :placeholder="$t('where_next')"
        v-model="querySearch"
        :class="lightBackground ? 'grey-background' : ''"
        v-loading="loading"
        @input="debounce(() => search())"
        @keydown.enter.prevent="search()"
      >
        <template #suffix>
          <Icons name="24px/search" @click="search()" />
        </template>
      </ElInput>
    </div>
    <div
      class="search-results"
      v-if="querySearch && showResults && width > 1100"
    >
      <div
        v-if="results?.search?.data?.length && querySearch.length >= 3"
        class="results"
      >
        <div
          v-if="showExperiences"
          class="result-item"
          @click="
            router.push(`/search?query=${querySearch}&type=experience`),
              (showResults = false)
          "
        >
          <div class="item-image">
            <img
              src="/logos/PALC_Marque_Black.svg"
              alt="logo"
              class="logo-img"
            />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('paradise_experiences_in', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showAccommodations"
          class="result-item"
          @click="
            router.push(`/search?query=${querySearch}&type=accommodation`),
              (showResults = false)
          "
        >
          <div class="item-image">
            <img src="/logos/Hotel.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('query_accommodation', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showRestaurants"
          class="result-item"
          @click="
            router.push(`/search?query=${querySearch}&type=restaurant`),
              (showResults = false)
          "
        >
          <div class="item-image">
            <img src="/logos/Food-and-Drink.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('query_food_and_drink', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showActivities"
          class="result-item"
          @click="
            router.push(`/search?query=${querySearch}&type=activity`),
              (showResults = false)
          "
        >
          <div class="item-image">
            <img src="/logos/Activity.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('activities_query', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showTours"
          class="result-item"
          @click="
            router.push(`/search?query=${querySearch}&type=tour`),
              (showResults = false)
          "
        >
          <div class="item-image">
            <img src="/logos/Tours.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('guided_tours_query', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          class="result-item"
          v-for="item in galleryItems"
          :key="item.uuid"
          @click="goTo(item)"
        >
          <div class="item-image">
            <img v-if="item.image" :src="item.image" loading="lazy" />
            <img
              v-else
              src="/logos/PALC_Marque_Black.svg"
              alt="logo"
              class="logo-img"
            />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span v-html="getSearchItemFormat(item.name, 'body1')"></span>
            </div>
            <div class="item-description">
              <span v-html="getSearchItemFormat(item.address, 'body2')"></span>
            </div>
          </div>
        </div>
        <div
          class="result-item"
          @click="
            router.push(`/search?query=${querySearch}`), (showResults = false)
          "
        >
          <div class="item-image">
            <img
              src="/logos/PALC_Marque_Black.svg"
              alt="logo"
              class="logo-img"
            />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('see_all_results_for', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-results">
        <span class="body1-strong">
          {{ $t('no_results_found_for', { query: querySearch }) }}
        </span>
        <div class="no-results-actions">
          <ElButton type="primary dark" @click="goToDonatella()">
            <Icons name="16px/message_writing_white" />
            {{ $t('to_talk_to_donatella') }}
          </ElButton>
        </div>
      </div>
    </div>
    <el-drawer
      v-if="width <= 1100"
      v-model="showResults"
      direction="rtl"
      class="left-drawer"
      :append-to-body="true"
      modal-class="left-drawer-modal"
    >
      <template #header>
        <div class="search-input">
          <ElInput
            class="landing-search grey-background"
            :placeholder="$t('where_next')"
            v-model="querySearch"
            :loading="loading"
            @input="debounce(() => search())"
          >
            <template #suffix>
              <Icons name="24px/search" />
            </template>
          </ElInput>
        </div>
      </template>
      <div
        v-if="querySearch && showResults && results?.search?.data?.length"
        class="results"
      >
        <div
          v-if="showExperiences"
          class="result-item"
          @click="router.push(`/search?query=${querySearch}&type=experience`)"
        >
          <div class="item-image">
            <img
              src="/logos/PALC_Marque_Black.svg"
              alt="logo"
              class="logo-img"
            />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('paradise_experiences_in', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showAccommodations"
          class="result-item"
          @click="
            router.push(`/search?query=${querySearch}&type=accommodation`)
          "
        >
          <div class="item-image">
            <img src="/logos/Hotel.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('query_accommodation', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showRestaurants"
          class="result-item"
          @click="router.push(`/search?query=${querySearch}&type=restaurant`)"
        >
          <div class="item-image">
            <img src="/logos/Food-and-Drink.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('query_food_and_drink', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showActivities"
          class="result-item"
          @click="router.push(`/search?query=${querySearch}&type=activity`)"
        >
          <div class="item-image">
            <img src="/logos/Activity.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('activities_query', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          v-if="showTours"
          class="result-item"
          @click="router.push(`/search?query=${querySearch}&type=tour`)"
        >
          <div class="item-image">
            <img src="/logos/Tours.svg" alt="logo" class="logo-img" />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('guided_tours_query', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
        <div
          class="result-item"
          v-for="item in galleryItems"
          :key="item.uuid"
          @click="goTo(item)"
        >
          <div class="item-image">
            <img v-if="item.image" :src="item.image" loading="lazy" />
            <img
              v-else
              src="/logos/PALC_Marque_Black.svg"
              alt="logo"
              class="logo-img"
            />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span v-html="getSearchItemFormat(item.name, 'body1')"></span>
            </div>
            <div class="item-description">
              <span v-html="getSearchItemFormat(item.address, 'body2')"></span>
            </div>
          </div>
        </div>
        <div
          class="result-item"
          @click="router.push(`/search?query=${querySearch}`)"
        >
          <div class="item-image">
            <img
              src="/logos/PALC_Marque_Black.svg"
              alt="logo"
              class="logo-img"
            />
          </div>
          <div class="item-text">
            <div class="item-title">
              <span
                v-html="
                  getSearchItemFormat(
                    $t('see_all_results_for', { query: querySearch }),
                    'body1'
                  )
                "
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-results">
        <span class="body1-strong">
          {{ $t('no_results_found_for', { query: querySearch }) }}
        </span>
        <div class="no-results-actions">
          <ElButton type="primary dark" @click="goToDonatella()">
            <Icons name="16px/message_writing_white" />
            {{ $t('to_talk_to_donatella') }}
          </ElButton>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { $eventBus } = useNuxtApp()
    const { width, height } = useBreakpoints()
    const { debounce } = getTableInitialVariables()
    return {
      $eventBus,
      route,
      router,
      width,
      debounce
    }
  },
  props: {
    lightBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      querySearch: '',
      results: null,
      showResults: false,
      showAccommodations: false,
      showExperiences: false,
      showRestaurants: false,
      showActivities: false,
      showTours: false
    }
  },
  watch: {
    showResults(newVal) {
      this.$emit('showResults', newVal)
    },
    'route.fullPath'(newVal) {
      this.querySearch = ''
      this.showResults = false
    }
  },
  computed: {
    galleryItems() {
      return this.results?.search?.data?.slice(0, 3) ?? []
    },
    isUserLogged() {
      return getAuthUser()?.value?.uuid ? true : false
    }
  },
  mounted() {
    this.$eventBus.$on('closeSearch', () => {
      this.showResults = false
      this.querySearch = ''
    })
  },
  methods: {
    async search(refetch = false) {
      if (this.querySearch?.length >= 3) {
        try {
          this.loading = true
          this.results = await apolloSearch(
            {
              search: this.querySearch,
              first: 50
            },
            false,
            {},
            refetch ? 'network-only' : null
          )
          this.showResults = true
          if (this.results?.search?.data?.length) {
            this.showExperiences = this.results.search.data.find(
              (i) => i.type === 'experience'
            )
              ? true
              : false
            this.showActivities = this.results.search.data.find(
              (i) => i.type === 'activity'
            )
              ? true
              : false
            this.showAccommodations = this.results.search.data.find(
              (i) => i.type === 'accommodation'
            )
              ? true
              : false
            this.showRestaurants = this.results.search.data.find(
              (i) => i.type === 'restaurant'
            )
              ? true
              : false
            this.showTours = this.results.search.data.find(
              (i) => i.type === 'tour'
            )
              ? true
              : false
          }
        } catch (err) {
          this.$showError(err, this.t)
          this.$sentry(err, 'search')
        } finally {
          this.loading = false
        }
      } else if (this.querySearch?.length < 3) {
        this.showResults = true
        this.results = null
      } else {
        this.showResults = false
      }
    },
    getSearchItemFormat(string, className) {
      if (string) {
        let result = `<span class="${className}">${string}</span>`

        const searchRegex = new RegExp(this.querySearch, 'gi')

        function preserveCase(match) {
          return `<span class="${className}-strong">${match}</span>`
        }

        return result.replace(searchRegex, preserveCase)
      }
      return '-'
    },
    goTo(item) {
      if (item.type === 'experience') {
        this.router.push(`/experiences/${item.uuid}`)
      } else if (item.type === 'activity') {
        this.router.push(`/activities/${item.uuid}`)
      } else if (item.type === 'accommodation') {
        this.router.push(`/accommodations/${item.uuid}`)
      } else if (item.type === 'restaurant') {
        this.router.push(`/restaurants/${item.uuid}`)
      }
      this.showResults = false
    }
  }
})
</script>

<style scoped lang="scss">
.search-container {
  position: absolute;
  top: 14px;
  &.not-logged {
    @media (min-width: 1100px) {
      left: calc(24px + 50px + 96px);
      right: calc(24px + 50px + 96px + 50px);
    }
    @media (max-width: 1100px) {
      left: calc(24px + 24px + 24px);
      right: calc(24px + 40px + 8px + 50px);
    }
  }
  &.logged {
    @media (min-width: 1100px) {
      left: calc(24px + 50px + 96px);
      right: calc(24px + 50px + 96px + 50px + 50px);
    }
    @media (max-width: 1100px) {
      left: calc(24px + 24px + 24px);
      right: calc(24px + 40px + 8px + 40px + 8px + 40px + 8px);
    }
  }
  .search-input {
    .landing-search {
      :deep(.el-input__wrapper) {
        .el-input__suffix {
          cursor: default !important;
          .el-input__suffix-inner {
            width: 0;
            i {
              position: relative;
              left: -20px;
            }
          }
        }
      }
    }

    &.results {
      .landing-search {
        :deep(.el-input__wrapper) {
          border-radius: 20px 20px 0 0 !important;
        }
      }
    }
  }

  .search-results {
    border-radius: 0 0 20px 20px;
    background: var(--brand-white);
    width: 100%;
    overflow-y: auto;

    .results {
      padding: 24px 24px 40px 24px;
      overflow-y: auto;
      max-height: 80vh;
      .result-item {
        cursor: pointer;
        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
        .item-image {
          width: 80px;
          height: 80px;
          border-radius: 3px;
          background-color: var(--brand-off-white);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          img {
            object-fit: cover;
            border-radius: 3px;
            width: 100%;
            height: 100%;
            &.logo-img {
              height: 32px !important;
              width: 32px !important;
              object-fit: contain !important;
            }
          }
        }

        .item-text {
          margin-left: 16px;
          display: inline-flex;
          align-items: flex-start;
          justify-content: center;
          height: 80px;
          vertical-align: top;
          flex-direction: column;
        }
      }
    }

    .no-results {
      padding: 24px;
      .no-results-actions {
        width: 100%;
        padding: 8px 0 8px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.left-drawer-modal {
  .el-drawer__header {
    .search-input {
      margin-right: 8px;
    }
  }
  .el-drawer__body {
    .results {
      .result-item {
        cursor: pointer;
        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
        .item-image {
          width: 80px;
          height: 80px;
          border-radius: 3px;
          background-color: var(--brand-off-white);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          img {
            object-fit: cover;
            border-radius: 3px;
            width: 100%;
            height: 100%;
            &.logo-img {
              height: 32px !important;
              width: 32px !important;
              object-fit: contain !important;
            }
          }
        }

        .item-text {
          margin-left: 16px;
          display: inline-flex;
          align-items: flex-start;
          justify-content: center;
          height: 80px;
          vertical-align: top;
          flex-direction: column;
          max-width: calc(100% - 96px);
        }
      }
    }

    .no-results {
      .no-results-actions {
        width: 100%;
        padding: 24px 0 8px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>