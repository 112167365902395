<template>
  <div>
    <div
      class="footer-container"
      v-if="width > 1100"
      :class="`${darkBackground ? 'dark-background' : ''} ${
        transparentBackground ? 'transparent-background' : ''
      }`"
    >
      <div class="footer-title">
        <div class="footer-logo">
          <img
            :src="
              route.fullPath === '/contact-us' ||
              darkBackground ||
              transparentBackground
                ? '/logos/PALC_Marque_White.svg'
                : '/logos/PALC_Marque_Black.svg'
            "
          />
          <div class="footer-logo-text">
            <span class="heading5">{{ $t('limitless_travel_one_place') }}</span>
          </div>
        </div>
        <div class="footer-about">
          <div class="footer-about-text">
            <span class="body2" @click="router.push('/about-us')">{{
              $t('about_paradise_a_la_carte')
            }}</span>
            <span class="body2" @click="router.push('/community')">{{
              $t('partners')
            }}</span>
            <span class="body2" @click="router.push('/contact-us')">{{
              $t('contact_us')
            }}</span>
            <span class="body2" @click="router.push('/faqs')">{{
              $t('faqs')
            }}</span>
          </div>
        </div>
      </div>
      <div class="footer-subtitle">
        <div class="footer-tos">
          <div class="block-tos">
            <span class="body2" @click="router.push('/terms-and-conditions')">
              {{ $t('terms_and_conditions') }}
            </span>
            <span class="body2" @click="router.push('/privacy')">
              {{ $t('privacy') }}
            </span>
            <span class="body2" @click="router.push('/cookies')">
              {{ $t('cookies_policies') }}
            </span>
          </div>
          <div class="block-copyright">
            <span class="small">
              © {{ new Date().getFullYear() }}
              {{ $t('palc_all_rights_reserved') }}
            </span>
          </div>
        </div>
        <div class="footer-social">
          <a target="_blank" href="https://www.instagram.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/contact-us' ||
                darkBackground ||
                transparentBackground
                  ? '16px/Social_Instagram_White'
                  : '16px/Social_Instagram'
              "
          /></a>
          <a target="_blank" href="https://www.facebook.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/contact-us' ||
                darkBackground ||
                transparentBackground
                  ? '16px/Social_Facebook_White'
                  : '16px/Social_Facebook'
              "
          /></a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/paradise-a-la-carte"
            ><Icons
              :name="
                route.fullPath === '/contact-us' ||
                darkBackground ||
                transparentBackground
                  ? '16px/Social_LinkedIn_White'
                  : '16px/Social_LinkedIn'
              "
          /></a>
        </div>
      </div>
    </div>
    <div
      class="footer-container"
      v-else
      :class="`${darkBackground ? 'dark-background' : ''} ${
        transparentBackground ? 'transparent-background' : ''
      }`"
    >
      <div class="footer-title">
        <div class="footer-logo">
          <div class="footer-logo-text">
            <span class="heading5">{{ $t('limitless_travel_one_place') }}</span>
          </div>
          <img
            :src="
              route.fullPath === '/contact-us' ||
              darkBackground ||
              transparentBackground
                ? '/logos/PALC_Marque_White.svg'
                : '/logos/PALC_Marque_Black.svg'
            "
          />
        </div>
        <div class="footer-about">
          <div class="footer-about-text">
            <span class="body2" @click="router.push('/about-us')">{{
              $t('about_paradise_a_la_carte')
            }}</span>
            <span class="body2" @click="router.push('/community')">{{
              $t('partners')
            }}</span>
            <span class="body2" @click="router.push('/contact-us')">{{
              $t('contact_us')
            }}</span>
            <span class="body2" @click="router.push('/faqs')">{{
              $t('faqs')
            }}</span>
          </div>
        </div>
      </div>
      <div class="footer-subtitle">
        <div class="footer-tos">
          <span class="body2" @click="router.push('/terms-and-conditions')">
            {{ $t('terms_and_conditions') }}
          </span>
          <span class="body2" @click="router.push('/privacy')">
            {{ $t('privacy') }}
          </span>
          <span class="body2" @click="router.push('/cookies')">
            {{ $t('cookies_policies') }}
          </span>
        </div>
        <div class="footer-social">
          <a target="_blank" href="https://www.instagram.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/contact-us' ||
                darkBackground ||
                transparentBackground
                  ? '16px/Social_Instagram_White'
                  : '16px/Social_Instagram'
              "
          /></a>
          <a target="_blank" href="https://www.facebook.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/contact-us' ||
                darkBackground ||
                transparentBackground
                  ? '16px/Social_Facebook_White'
                  : '16px/Social_Facebook'
              "
          /></a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/paradise-a-la-carte"
            ><Icons
              :name="
                route.fullPath === '/contact-us' ||
                darkBackground ||
                transparentBackground
                  ? '16px/Social_LinkedIn_White'
                  : '16px/Social_LinkedIn'
              "
          /></a>
        </div>
        <div class="footer-copyright">
          <span class="small">
            © {{ new Date().getFullYear() }}
            {{ $t('palc_all_rights_reserved') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default defineComponent({
  name: 'Footer',
  props: {
    darkBackground: {
      type: Boolean,
      default: false
    },
    transparentBackground: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { width } = useBreakpoints()
    return {
      router,
      route,
      width
    }
  },
  data() {
    return {
      window
    }
  }
})
</script>

<style scoped lang="scss">
.footer-container {
  background: var(--brand-white);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;

  @media (max-width: 1100px) {
    height: 375px;
    padding: 40px 24px;
  }
  @media (min-width: 1100px) {
    height: 247px;
    padding: 40px 0px;
  }
  @media (min-width: 1100px) {
    .footer-title {
      margin: 0 auto;
      width: 85%;
      margin-bottom: 40px;
      .footer-logo {
        display: inline-block;
        .footer-logo-text {
          display: inline-block;
        }
        img {
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: -4px;
        }
      }
      .footer-about {
        float: right;
        display: inline-block;
        .footer-about-text {
          display: inline-block;
          .body2 {
            cursor: pointer;
            &:not(:last-of-type) {
              margin-right: 32px;
            }
          }
        }
      }
    }
    .footer-subtitle {
      margin: 0 auto;
      width: 85%;
      border-top: 1px solid var(--brand-off-black);
      padding-top: 24px;
      .footer-tos {
        display: inline-block;
        color: var(--brand-mid-grey);
        .block-tos {
          .body2 {
            margin-right: 32px;
            cursor: pointer;
          }
        }
        .block-copyright {
          margin-top: 10px;
        }
      }
      .footer-social {
        float: right;
        display: inline-block;
        a {
          display: inline-block;
          &:not(:last-of-type) {
            margin-right: 24px;
          }
        }
      }
    }
  }
  @media (max-width: 1100px) {
    .footer-title {
      margin: 0 auto;
      width: 95%;
      margin-bottom: 24px;
      .footer-logo {
        margin-bottom: 24px;
        .footer-logo-text {
          width: calc(100% - 24px - 16px);
          display: inline-block;
          margin-right: 16px;
          .heading5 {
            font-size: 24px !important;
          }
        }
        img {
          display: inline-block;
          vertical-align: top;
        }
      }
      .footer-about {
        .footer-about-text {
          .body2 {
            cursor: pointer;
            display: block;
            &:not(:first-of-type) {
              margin-top: 8px;
            }
          }
        }
      }
    }
    .footer-subtitle {
      margin: 0 auto;
      width: 95%;
      border-top: 1px solid var(--brand-mid-grey);
      padding-top: 24px;
      .footer-tos {
        display: inline-block;
        color: var(--brand-mid-grey);
        .body2 {
          cursor: pointer;
          display: block;
          &:not(:first-of-type) {
            margin-top: 8px;
          }
        }
      }
      .footer-social {
        float: right;
        display: inline-block;
        i,
        a {
          display: inline-block;
          &:not(:last-of-type) {
            margin-right: 24px;
          }
        }
      }
      .footer-copyright {
        color: var(--brand-mid-grey);
        margin-top: 12px;
      }
    }
  }

  &.dark-background {
    background-color: var(--brand-off-black) !important;
    .footer-title {
      color: var(--brand-white) !important;
    }
    .footer-subtitle {
      color: var(--brand-white) !important;
      border-top: 1px solid var(--brand-white);
      .footer-tos {
        color: var(--brand-white) !important;
      }
    }
  }

  &.transparent-background {
    background-color: var(--tins-dark-3);
    backdrop-filter: blur(2.5px);
    .footer-title {
      color: var(--brand-white) !important;
    }
    .footer-subtitle {
      color: var(--brand-white) !important;
      border-top: 1px solid var(--brand-white);
      .footer-tos {
        color: var(--brand-white) !important;
      }
    }
  }
}
</style>